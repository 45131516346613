function omitDeep(obj, properties) {
  return JSON.parse(JSON.stringify(obj), (key, value) =>
    properties.includes(key) ? undefined : value,
  );
}

/**
 * Remove GraphQL properties from an object.
 * @param {object} data
 * @returns {object}
 */
export default function omitGraphQlProps(data) {
  return omitDeep(data, ['__typename']);
}
